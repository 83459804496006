<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[
          ['Dashboard', '/'],
          ['Product Setup', '/product-setup'],
          ['Vouchers'],
        ]"
      />
    </template>
    <template #header_page_title> Vouchers </template>
    <template #header_page_actions>
      <div class="px-3 md:px-10 flex items-center">
        <button class="btn-action" @click="isAddVoucher = true">Add Voucher</button>
      </div>
    </template>
    <Menu></Menu>
    <loading
      :loading="loading"
      class="bg-gray-200 w-full h-full overflow-y-auto scrolling-touch overflow-x-hidden p-5"
    >
      <div class="p-5 bg-white border">
        <div class="flex flex-col xs:table w-full border-collapse">
          <div class="hidden xs:table-row">
            <b class="table-cell px-1 pb-2">Code</b>
            <b class="table-cell px-1 pb-2">Discount</b>
            <b class="table-cell px-1 pb-2">Limited To</b>
            <b class="hidden lg:table-cell px-1 pb-2">Account Limit</b>
            <b class="hidden lg:table-cell px-1 pb-2">Uses</b>
            <b class="table-cell px-1 pb-2">Start / End Date</b>
            <b class="sm:hidden lg:table-cell px-1 pb-2">Type</b>
            <div class="table-cell px-1 pb-2">
              <div class="flex gap-2 items-center">
                <b>Status</b>
                <Tooltip class="hidden xs:inline">
                  <span>Status is determined by date range, remaining uses & account limit.</span>
                </Tooltip>
                <span class="relative capitalize">
                  <div class="bg-gray-400 rounded px-1 py-2 text-white cursor-pointer flex items-center gap-1" @click="status_dropdown = !status_dropdown">
                    <i class="fal fa-filter mx-1"></i>
                    <b v-if="$route.query.statusFilter">
                      {{ setDiscountStatus($route.query.statusFilter) }}
                    </b>
                    <i v-if="status_dropdown" class="fal fa-chevron-up mx-1"></i>
                    <i v-else class="fal fa-chevron-down mx-1"></i>
                  </div>
                  <div
                    v-if="status_dropdown"
                    class="text-black absolute bg-white z-10 border border-gray-300 text-xs rounded right-0 mt-1"
                    style="top: 100%"
                  >
                    <div class="p-1 border-b border-gray-300 font-medium flex justify-between m-2">
                      <div class="flex flex-col justify-around pr-6 whitespace-no-wrap">Voucher Status</div>
                      <div class="flex flex-col justify-around">
                        <i class="fal fa-times" @click.stop="status_dropdown = false"></i>
                      </div>
                    </div>
                    <div class="mt-2">
                      <div
                        v-for="status in [enums.discountStatus.DISABLED, enums.discountStatus.ENABLED, enums.discountStatus.EXPIRED, enums.discountStatus.REDEEMED]"
                        :key="status.id"
                        class="py-2 px-4 hover:bg-gray-200 cursor-pointer"
                        @click="toggleStatusFilter(status)"
                      >
                        <b class="capitalize">
                          <span
                            class="inline-block rounded-full w-2 h-2 mr-2"
                            :class="{
                              'bg-red-500': status === enums.discountStatus.DISABLED || enums.discountStatus.EXPIRED,
                              'bg-green-500': status === enums.discountStatus.ENABLED,
                              'bg-blue-500': status === enums.discountStatus.REDEEMED
                            }"
                          ></span>
                          {{ setDiscountStatus(status) }}
                        </b>
                        <span v-show="$route.query.statusFilter == status" class="text-green-600 pl-2">
                          <i class="fal fa-check"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </span>
              </div>
            </div>
          </div>
          <tr><td colspan="100%" class="hidden xs:table-cell border-t"></td></tr>
          <div
            v-for="voucher in vouchers"
            :key="voucher.id" 
            class="table-row border-b pb-5 mb-5 xs:pb-0 xs:mb-0"
          >
            <div class="flex xs:table-cell p-1 xs:px-1 xs:py-4 pr-3">
              <b class="xs:hidden w-24">Code</b>
              <div class="flex gap-2 items-center relative">
                <Tooltip class="hidden xs:inline" :is-left="false">
                  <span>{{ voucher.description }}</span>
                </Tooltip>
                <div
                  class="text-white py-1 px-2 rounded cursor-pointer flex gap-5 items-center max-w-xs"
                  :class="[
                    copiedCode === voucher.code ? 'bg-green-600' : 'bg-brand-primary',
                  ]"
                  @click.prevent="copyURL(voucher.code)"
                >
                  <span>{{ voucher.code }}</span>
                  <transition name="fade-in" mode="out-in">
                    <i
                      v-if="copiedCode === voucher.code"
                      class="fal fa-check ml-3 ml-auto"
                    ></i>
                    <i v-else class="fal fa-copy ml-3 ml-auto"></i>
                  </transition>
                </div>
              </div>
            </div>
            <div class="flex xs:table-cell p-1 xs:px-1 xs:py-4">
              <b class="xs:hidden w-24">Discount</b>
              <strong v-if="voucher.discountType === enums.discountType.PERCENTAGE">
                {{ voucher.discountAmount.toPrecision(3) }}%
              </strong>
              <strong v-else-if="voucher.discountType === enums.discountType.FIXED && currencyCode">
                <span>{{ currencySymbol(currencyCode) + voucher.discountAmount.toPrecision(3) }}</span>
              </strong>
            </div>
            <div class="flex xs:table-cell p-1 xs:px-1 xs:py-4">
              <b class="xs:hidden w-24">Limited To</b>
              <span v-if="voucher.customersLimitedTo.length > 0">
                <span
                  v-for="customer in voucher.customersLimitedTo"
                  :key="customer.id"
                  class="flex flex-wrap hover:text-gray-500 cursor-pointer"
                  @click="$router.push('/customer/' + customer.id + '/vouchers')"
                >               
                  {{ customer.name }}
                </span>
              </span>
              <span v-else>-</span>
            </div>
            <div class="flex xs:hidden lg:table-cell p-1 xs:px-1 xs:py-4">
              <b class="xs:hidden w-24">Account <br> Limit</b>
              {{ voucher.usesPerAccount }}
            </div>
            <div class="flex xs:hidden lg:table-cell p-1 xs:px-1 xs:py-4">
              <b class="xs:hidden w-24">Uses</b>
              {{ voucher.currentUses + ' out of ' + voucher.totalUses }}
            </div>
            <div class="flex xs:table-cell p-1 xs:px-1 xs:py-4">
              <b class="xs:hidden w-24">Start / <br> End Date</b>
              <div>{{ toLocaleDateString(voucher.startDate) + ' - ' + toLocaleDateString(voucher.endDate) }}</div>
            </div>
            <div class="px-1 py-4 flex sm:hidden lg:table-cell">
              <b class="xs:hidden w-24">Type</b>
              <span v-if="voucher.perOrderOrItem === enums.discountPerOrderOrItem.ORDER">Order</span>
              <span v-else-if="voucher.perOrderOrItem === enums.discountPerOrderOrItem.ITEM">Item</span>
            </div>
            <div class="xs:w-32 flex xs:table-cell p-1 xs:px-1 xs:py-4">
              <b class="xs:hidden w-24">Status</b>
              <div
                class="text-white text-center px-2 py-1 rounded capitalize"
                :class="{'bg-red-500': voucher.status === 0 || voucher.status === 2, 'bg-green-500': voucher.status === 1, 'bg-blue-500': voucher.status === 3 }"
              >
                {{ setDiscountStatus(voucher.status) }}
              </div>
            </div>
            <div class="flex xs:table-cell p-1 xs:px-1 xs:py-4 pl-3"> 
              <div class="ml-auto flex flex-col justify-center">
                <div class="flex gap-1 text-center">
                  <div
                    class="rounded text-green-600 border border-green-600 cursor-pointer flex flex-col justify-center"
                    style="height: 30px; width: 30px; line-height: 30px;"
                    @click.stop="voucherToUpdate = voucher"
                  >
                    <i class="fa fa-pencil text-xs"></i>
                  </div>
                  <div
                    class="rounded text-red-600 border border-red-600 cursor-pointer flex flex-col justify-center"
                    style="height: 30px; width: 30px; line-height: 30px;"
                    @click.stop="deleteDiscountVoucher(voucher.id, voucher.code)"
                  >
                    <i class="fa fa-trash text-xs"></i>
                  </div>
                </div>
              </div>
            </div>      
          </div>
        </div>   
        <div v-if="vouchers.length === 0" key="none-found" class="flex items-center w-full bg-white p-3 xs:p-10">
          <div>
            No vouchers to show
          </div>
          <a v-if="$route.query.statusFilter" class="ml-auto btn-action" @click.stop="setDefaultFilter()">
            Clear Filters
          </a>
        </div>
        <div v-if="vouchers.length > 0" class="mt-3">
          <Pagination
            :limit="limit"
            :total_items="total"
            @load-feed="listDiscountVouchers"
          ></Pagination>
        </div>
      </div>
      <Update
        v-if="isAddVoucher || (typeof voucherToUpdate !== 'undefined')"
        :modal_open="isAddVoucher || (typeof voucherToUpdate !== 'undefined')"
        :voucher="voucherToUpdate"
        :currency-symbol="currencySymbol(currencyCode)"
        @close="isAddVoucher = false, voucherToUpdate = undefined"
        @updated="listDiscountVouchers"
      />
    </loading>
  </touch-layout>
</template>

<script>
import Menu from '@/components/business/productSetup/Menu.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
import currencyHelper from '@/mixins/currencyHelper';
import Tooltip from '@/components/shared/Tooltip.vue';
import Update from '@/components/business/productSetup/vouchers/Update.vue';
import Pagination from '@/components/shared/Pagination.vue';

export default {
  components: {
    Menu,
    Breadcrumbs,
    Tooltip,
    Update,
    Pagination
  },
  mixins: [
    currencyHelper
  ],
  data() {
    return {
      isAddVoucher: false,
      currencyCode: undefined,
      vouchers: [],
      voucherToUpdate: undefined,
      limit: 20,
      offset: 0,
      total: 0,
      loading: true,
      copiedCode: undefined,
      timeout: undefined,
      status_dropdown: false
    };
  },
  watch: {
    '$route.query': {
      async handler(query) {
        await this.listDiscountVouchers();
      },
    },
  },
  async created () {
    this.currencyCode = await this.getCurrencyCode()
    await this.listDiscountVouchers()
    this.loading = false
  },
  methods: {
    toLocaleDateString (date_string) {
      const date = new Date (date_string)
      const dateString = date.toDateString().split(' ')
      dateString.shift()
      const year = new Date().getFullYear() 
      const toDateYear = dateString.pop()
      if (toDateYear != year) {
        dateString.push(toDateYear)
      }
      return dateString.join(' ')
    },
    setDiscountStatus(status) {
      const discountStatus = Object.keys(this.enums.discountStatus).map((key) => [key, this.enums.discountStatus[key]]);
      return discountStatus[status.toString()][0].toLowerCase()
    },
    async getCurrencyCode () {
      const currencies =  (await this.$store.dispatch('touch/commonGetCurrencies'))
      const currency = currencies.find((currency) => currency.isDefault);
      return currency.code
    },
    async listDiscountVouchers (Offset = 0) {
      this.loading = true
      const Status = this.$route.query.statusFilter ? parseInt(this.$route.query.statusFilter) : undefined
      const { vouchers, totalVouchers }= await window.touch.ListDiscountVouchers({ Limit: this.limit, Offset, Status })
      this.vouchers = vouchers
      this.total = totalVouchers
      this.isAddVoucher = false
      this.voucherToUpdate = undefined
      this.loading = false
    },
    async deleteDiscountVoucher (id, code) {
      this.alertBox()
        .fire({
          title: 'Are you sure you want to delete ' + code + ' voucher?',
          text: 'This action cannot be undone.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Confirm',
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            await window.touch.DeleteDiscountVoucher(id)
            await this.listDiscountVouchers()
          }
        });
    },
    async copyURL(code) {
      clearTimeout(this.timeout)
      this.copiedCode = undefined;
      this.copiedCode = code;

      try {
        await navigator.clipboard.writeText(code);
      } catch ($e) {
        this.alertBox().fire('Copy Failed');
        this.copiedCode = undefined;
        return;
      }

      this.timeout = setTimeout(() => {
        this.copiedCode = undefined;
      }, 2000);
    },
    toggleStatusFilter(statusFilter) {
      this.$router.push({ query: { ...this.$route.query, statusFilter: this.$route.query.statusFilter == statusFilter ? undefined : statusFilter } }).catch(() => {});
      this.status_dropdown = false
    },
    setDefaultFilter() {
      this.$router.replace({ query: undefined }).catch(() => {});
      this.status_dropdown = false
    },
  }
};
</script>
