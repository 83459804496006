<template>
  <loading :loading="loading" class="bg-gray-200 w-full h-full p-3 md:p-10">
    <slot name="tabs"></slot>
    <div
      v-if="contracts.length > 0"
      key="orders_list"
      class="bg-white p-3 md:p-10 pb-6 border"
      data-index="1"
    >
      <table class="table-lined xs:table-unstacked _with-hover w-full">
        <thead>
          <tr>
            <th class="w-10">#</th>
            <th> {{ $route.query.type === "order" ? "Date Placed" : "Date Created" }}</th>
            <th>{{ $route.query.type === "order" ? "Order" : "Quote" }} Reference</th>
            <th v-if="typeof customerId === 'undefined' || customerId === null">Customer</th>
            <th>Net Sell</th>
            <th v-if="$route.query.type === 'order'">Status</th>
            <th>Visibilty To Portal</th>
            <th v-if="$route.query.type === 'order' || $route.query.type === 'quote'" class="w-16">PDF</th>
            <th v-if="$route.query.type === 'order' && isSuperuser" class="w-16">XML</th>
            <slot name="show-imports"></slot>
          </tr>
        </thead>
        <tbody>
          <router-link
            v-for="contract in contracts"
            :key="contract.id"
            tag="tr"
            :to="contract.url"
            class="cursor-pointer pointer-events-none"
            :class="{
              'pointer-events-auto': [
                enums.orderStatusSaveStates.NOT_APPLICABLE,
                enums.orderStatusSaveStates.COMPLETED,
              ].includes(contract.saveState),
              'fadeInAndOut': contract.saveState === enums.orderStatusSaveStates.STARTED
            }"
          >
            <td>
              <div class="td-title w-24">Number:</div>
              <div class="td-content">{{ contract.customerCounter }}</div>
            </td>
            <td>
              <div class="td-title w-24">
                {{ $route.query.type === "order" ? "Date Placed" : "Date Created" }}:
              </div>
              <div class="td-content">{{ formatDate(contract.date) }}</div>
            </td>
            <td>
              <div class="td-title w-24">Reference:</div>
              <div class="td-content  w-full">
                {{ contract.reference }}
              </div>
            </td>
            <td v-if="typeof customerId === 'undefined' || customerId === null">
              <div class="td-title w-24">Customer:</div>
              <div class="td-content w-full">{{ contract.customerName }}</div>
            </td>
            <td v-if="$route.query.type === 'quote' && contract.saveState === enums.orderStatusSaveStates.FAILED">
              <div class="td-content font-bold text-red-500">
                Sorry, problem saving this job. Please re-input
              </div>
            </td>
            <td v-else-if="contract.jobs.length > 0">
              <div class="td-title w-24">Net Sell:</div>
              <div class="td-content" v-html="formatCurrency(contract.currencyCode, contract.jobs[0].priceBeforeTax)"></div>
            </td>
            <td v-else>  
              <div class="td-title w-24">Net Sell:</div>
              <div class="td-content">-</div>
            </td>
            <td v-if="$route.query.type === 'order'">
              <div class="td-title w-24">Status:</div>
              <div class="td-content" :class="{ 'font-bold text-red-500': contract.saveState === enums.orderStatusSaveStates.FAILED }"> 
                {{ statusText(contract) }}
              </div>
            </td>
            <td v-if="!lazySaving(contract)" class="flex items-center">
              <div class="td-title w-24">Visibilty To Portal:</div>
              <button v-if="!contract.visibleToPortal" class="btn-action" @click.stop="toggleVisibility(contract)">
                Release To Portal
              </button>
              <i v-else class="fa fa-check text-green-600"></i>
            </td>
            <td v-else>
              <div>-</div>
            </td>
            <td v-if="$route.query.type === 'order' &&!lazySaving(contract) && contract.saveState !== enums.orderStatusSaveStates.FAILED">
              <div class="td-title w-24">PDF:</div>
              <div class="td-content">
                <i class="fa fa-download" @click.stop="showPdf(contract)"></i>
              </div>
            </td>
            <td v-else-if="$route.query.type === 'order'" class="hidden xs:table-cell">
              <div class="td-title w-24">PDF:</div>
              <div>-</div>
            </td>
            <td
              v-if="
                $route.query.type === 'order' &&
                  !lazySaving(contract) &&
                  isSuperuser &&
                  contract.saveState !== enums.orderStatusSaveStates.FAILED
              "
            >
              <div class="td-title w-24">XML:</div>
              <div class="td-content">
                <i class="fa fa-download" @click.stop="getJobXML(contract)"></i>
              </div>
            </td>
            <td v-else-if="$route.query.type === 'order'" class="hidden xs:table-cell">
              <div class="td-title w-24">XML:</div>
              <span>-</span>
            </td>
            <td v-if="$route.query.type === 'quote' && !lazySaving(contract) && contract.saveState !== enums.orderStatusSaveStates.FAILED">
              <div class="td-title w-24">PDF:</div>
              <div class="td-content">
                <i class="fa fa-download" @click.stop="getQuotePDF(contract)"></i>
              </div>
            </td>
            <td v-else-if="$route.query.type === 'quote'" class="hidden xs:table-cell">
              <div class="td-title w-24">PDF:</div>
              <span>-</span>
            </td>
            <slot name="show-contract-imported" :contract_data="contract"></slot>
            <td v-if="contract.saveState !== enums.orderStatusSaveStates.FAILED" class="td-action-buttons">
              <div>
                <div class="table-edit-btn">
                  <i class="fa fa-pencil"></i>
                </div>
              </div>
            </td>
          </router-link>
        </tbody>
      </table>
    </div>
    <div v-else key="none-found" class="bg-white p-5 md:p-10 border">
      <div v-if="$route.query.search" class="flex justify-between">
        <div>No matches found.</div>
        <div>
          <a class="btn-action" @click.stop="$router.push({ query: { ...$route.query, search: undefined } })"> Clear Search </a>
        </div>
      </div>
      <div v-else>No {{ $route.query.type + 's' }} to show</div>
    </div>
    <div v-if="contracts.length > 0" key="pagination" class="mt-3 pb-10">
      <pagination :total_items="total_items" :limit="limit" @load-feed="loadContracts"></pagination>
    </div>
  </loading>
</template>

<script>
import dateHelper from '@/mixins/dateHelper';
import currencyHelper from '@/mixins/currencyHelper';
import Pagination from '@/components/shared/Pagination.vue';
import pollContractStates from '@/mixins/pollContractStates';

export default {
  name: 'SalesDashboardTable',
  components: {
    pagination: Pagination,
  },
  mixins: [dateHelper, currencyHelper, pollContractStates],
  props: {
    customerId: String,
    type: String,
    status: String
  },
  data() {
    return {
      loading: true,
      contracts: [],
      limit: 10,
      total_items: 0,
      contractsBeingProcessed: [],
      isSuperuser: false,
      customQuoteStageId: undefined,
    };
  },
  watch: {
    '$route.query': {
      async handler() {
        if (this.$route.query.type || (this.$route.query.type && this.$route.query.status)) {
          await this.loadContracts();
          const contractType = this.$route.query.type === 'order' ? window.touch.CONTRACT_TYPE_ORDER : window.touch.CONTRACT_TYPE_QUOTE;
          await this.pollContractStates(contractType, this.contracts);
          this.loading = false;
        }
      },
      immediate: true,
    },
  },
  async mounted() {
    this.isSuperuser = this.$store.state.auth.superuser;
  },
  methods: {
    lazySaving(contract) {
      return contract.saveState === this.enums.orderStatusSaveStates.STARTED;
    },
    async showPdf(contract) {
      this.loading = true;
      await window.touch.downloadOrderReport(contract.id, contract.jobs[0].jobKey);
      this.loading = false;
    },
    async toggleVisibility(contract) {
      // we don't need to do anything if the contract is being processed.
      if (this.contractsBeingProcessed.length && this.contractsBeingProcessed.find((x) => x === contract.id) !== undefined) {
        return;
      }
      this.contractsBeingProcessed.push(contract.id);
      const res = await window.touch.contractUpdateVisibility(contract.id, {
        visibleToPortal: !contract.visibleToPortal,
      });
      if (res.status === 200) {
        const originalOrder = this.contracts.find((x) => x.id === contract.id);
        originalOrder.visibleToPortal = !originalOrder.visibleToPortal;
      } else if (res?.data?.message) {
        window.alertBox.fire(res.data.message);
      }
      // we can now safely remove the contract from the processing list
      this.contractsBeingProcessed = this.contractsBeingProcessed.filter((x) => x !== contract.id);
    },
    async loadContracts(offset = 0) {
      this.loading = true;
      
      const options = {
        orderStatus: this.$route.query.status,
        limit: this.limit,
        offset,
        customerId: Number(this.customerId),
        search: this.$route.query.search,
        customQuoteStageId: this.customQuoteStageId,
      };

      let response;

      if (this.$route.query.type === 'order') {
        response = await this.$store.dispatch('touch/loadOrders', options);
      }
      if (this.$route.query.type === 'quote') {
        response = await this.$store.dispatch('touch/loadQuotesForAdmin', options);
      }
      this.contracts = response.orders.map((order) => ({
        ...order,
        url: `/customer/${this.customerId ? this.customerId : order.customerId}/sales-dashboard${order.url}/${order.jobs[0] ? order.jobs[0].jobKey : 1}`,
        customerName: response.assignees.find((x) => x.customerId === order.customerId)?.name,
      }));
      this.total_items = response.total_items;
      this.loading = false;
    },
    async getQuotePDF(contract) {
      this.loading = true;
      await window.touch.downloadQuotePDF(contract.id, contract.jobs[0].jobKey);
      this.loading = false;
    },
    async getJobXML(contract) {
      this.loading = true;
      await window.touch.downloadJobXML(contract.id, contract.jobs[0].jobKey);
      this.loading = false;
    },
    statusText(contract) {
      if (
        [this.enums.PaymentStatus.AWAITING, this.enums.PaymentStatus.FAILED].includes(
          contract.orderPaymentStatus,
        ) &&
        contract.finalised
      ) {
        return 'Placed (Pending Payment)';
      }
      if (contract.saveState === this.enums.orderStatusSaveStates.COMPLETED) {
        switch (contract.orderStatus) {
          case window.enum.orderStatus.NONE:
            return 'None';
          case window.enum.orderStatus.PENDING:
            return 'Pending';
          case window.enum.orderStatus.PLACED:
            return 'Placed';
          case window.enum.orderStatus.CANCELLED:
            return 'Cancelled';
          default:
            return 'None';
        }
      } else {
        switch (contract.saveState) {
          case this.enums.orderStatusSaveStates.NOT_STARTED:
            return 'Not Started';
          case this.enums.orderStatusSaveStates.STARTED:
            return 'Started';
          case this.enums.orderStatusSaveStates.FAILED:
            return 'Sorry, problem saving this job. Please re-input';
          default:
            return 'N/A';
        }
      }
    },
  },
};
</script>

<style scoped>

.fadeInAndOut {
  opacity: 1;
  animation: fade 2s linear infinite;
}

@keyframes fade {
  0%,100% { opacity: 0.2 }
  50% { opacity: 1 }
}

</style>