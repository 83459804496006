<template>
  <portal v-if="modal_open" to="portal_popup">
    <modal-window :modal_open="modal_open" title="Add Voucher" @close="$emit('close')">
      <div class="flex flex-col 2xs:grid grid-cols-2 gap-3">
        <div>
          Code
          <div class="font-medium">
            <input v-model="code" class="form-control" />
          </div>
        </div>
        <div>
          Description
          <div class="font-medium">
            <input v-model="description" class="form-control" />
          </div>
        </div>
        <div class="col-span-2">
          Amount
          <div class="flex gap-5 items-center">
            <div class="flex-1 flex justify-between gap-2">
              <button
                class="btn flex-1"
                :class="{ 'btn-action': discountType === enums.discountType.FIXED }"
                @click="discountType = enums.discountType.FIXED"
              >
                Fixed
              </button>
              <button
                class="btn flex-1"
                :class="{ 'btn-action': discountType === enums.discountType.PERCENTAGE }"
                @click="discountType = enums.discountType.PERCENTAGE"
              >
                Percentage
              </button>
            </div>
            <div class="flex-1 flex border rounded border-black h-10 overflow-hidden">
              <div v-if="discountType === enums.discountType.FIXED" class="border-r border-black px-3 flex flex-col justify-center">{{ currencySymbol }}</div>
              <input v-model="discountAmount" min="0" type="number" class="px-3 flex-grow" />
              <div v-if="discountType === enums.discountType.PERCENTAGE" class="ml-auto border-l border-black px-3 flex flex-col justify-center">%</div>
            </div>
          </div>
        </div>
        <div>
          Date Range
          <div class="relative">
            <div class="absolute w-full h-full opacity-0 pointer-events-none left-0">
              <datepicker
                ref="datepicker"
                v-model="date_range"
                :open="isDatePicker"
                placeholder="Select date range"
                type="date"
                value-type="format"
                :format="'YYYY-MM-DD'"
                range
              ></datepicker>
            </div>
            <div
              class="flex flex-col justify-center relative h-10 border border-black rounded  px-2 overflow-hidden clickable cursor-pointer"
              @click="isDatePicker = !isDatePicker"
            >
              <div v-if="date_range" class="flex items-center gap-2">
                <span>{{ (new Date (date_range[0])).toLocaleDateString() }}</span>
                <span>-</span>
                <span>{{ (new Date (date_range[1])).toLocaleDateString() }}</span>
                <i class="fal fa-times mx-1 cursor-pointer ml-auto z-50" @click.stop="date_range = undefined, isDatePicker = false"></i>
              </div>
              <div v-else class="text-gray-500 justify-center">
                <div>Select date range...</div>
              </div>
            </div>
          </div>
        </div>
        <div>
          Type
          <div class="flex justify-between gap-2">
            <button
              class="btn flex-1"
              :class="{ 'btn-action': perOrderOrItem === enums.discountPerOrderOrItem.ORDER }"
              @click="perOrderOrItem = enums.discountPerOrderOrItem.ORDER"
            >
              Order
            </button>
            <button
              class="btn flex-1"
              :class="{ 'btn-action': perOrderOrItem === enums.discountPerOrderOrItem.ITEM }"
              @click="perOrderOrItem = enums.discountPerOrderOrItem.ITEM"
            >
              Item
            </button>
          </div>
        </div>
        <div>
          Uses Per Account
          <div class="font-medium">
            <input v-model.number="usesPerAccount" type="number" min="0" class="form-control" />
          </div>
        </div>
        <div>
          Total Uses
          <div class="font-medium">
            <input v-model.number="totalUses" type="number" min="0" class="form-control" />
          </div>
        </div>
        <div>
          Customers Limited to
          <div>
            <div class="relative">
              <input
                v-model="search"
                placeholder="Search customers..."
                class="h-10 p-3 border border-black w-full rounded-t placeholder-gray-500"
                :class="{'rounded': customers.length === 0}"
                @input="delaySearch($event.target.value)"
              />
              <div
                v-if="customers.length > 0"
                class="absolute border-l border-r border-b border-black p-3 w-full bg-white z-50"
              >
                <div
                  v-for="customer in customers"
                  :key="customer.id"
                  class="cursor-pointer hover:text-gray-500"
                  :class="{ 'opacity-50 pointer-events-none text-green-500': customersLimitedTo.some(x => x.id === customer.id) }"
                  @click="setLimitedTo(customer.id, customer.name)"
                >
                  {{ customer.name }}
                </div>
              </div>
            </div>
            <div
              v-for="customer in customersLimitedTo"
              :key="customer.id"
              class="mt-2 flex items-center gap-2 py-1 px-2 bg-gray-500 rounded cursor-pointer"
              @click="setLimitedTo(customer.id, customer.name)"
            >
              <div class="flex items-center gap-2 w-full">
                <div>{{ customer.name }}</div>
                <div class="ml-auto">
                  <i class="fal fa-times"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-right mt-auto flex flex-col 2xs:block pt-20">
        <button class="btn btn-lg 2xs:mr-1 mb-1 2xs:mb-0" @click.prevent="$emit('close')">
          Cancel
        </button>
        <button class="btn-action btn-lg" @click.prevent.stop="saveVoucher">
          Save Changes
        </button>
      </div>
    </modal-window>
  </portal>
</template>

<script>

import Datepicker from 'vue2-datepicker';
import validate from 'validate.js';

export default {
  components: {
    Datepicker
  },
  props: {
    modal_open: Boolean,
    voucher: Object,
    currencySymbol: String
  },
  data() {
    return {
      code: '',
      description: '',
      discountType: 1,
      discountAmount: 0,
      date_range: undefined,
      isDatePicker: false,
      usesPerAccount: 0,
      totalUses: 0,
      perOrderOrItem: 0,
      search: '',
      timeout: undefined,
      customers: [],
      customersLimitedTo: []
    };
  },
  watch: {
    'date_range' () {
      this.isDatePicker = false
    },
  },
  created () {
    if (this.voucher) {
      this.code = this.voucher.code;
      this.description = this.voucher.description
      this.discountType = this.voucher.discountType
      this.discountAmount = this.voucher.discountAmount
      this.usesPerAccount = this.voucher.usesPerAccount
      this.totalUses =this.voucher.totalUses
      this.perOrderOrItem = this.voucher.perOrderOrItem
      this.customersLimitedTo = this.voucher?.customersLimitedTo || []
      this.date_range = []
      this.date_range[0] = this.voucher.startDate
      this.date_range[1] = this.voucher.endDate
    }
  },
  methods: {
    delaySearch (search) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.searchCustomers(search)
      }, 300);
    },
    async searchCustomers (search) {
      if (search) {
        const response = await this.$store.dispatch('touch/staffListCustomers', {
          limit: 5,
          offset: 0,
          query: search,
        });
        this.customers = response.customers;
      } else {
        this.customers = []
      }
    },
    setLimitedTo (customerId, name) {
      if (this.customersLimitedTo.some(x => x.id === customerId)) {
        const index = this.customersLimitedTo.findIndex(x => x.id === customerId)
        this.customersLimitedTo.splice(index, 1)
      } else {
        this.customersLimitedTo.push({
          id: customerId,
          name: name
        })
      }
      this.search = ''
      this.customers = []
    },
    async saveVoucher() {
      const validationErrors = validate(
        {
          code: this.code,
          description: this.description,
          discountAmount: this.discountAmount,
          usesPerAccount: this.usesPerAccount,
          totalUses: this.totalUses,
          dateRange: this.date_range
        },
        {
          code: {
            presence: { allowEmpty: false },
            length: {
              maximum: 20,
            },
          },
          description: {
            presence: { allowEmpty: false },
            length: {
              maximum: 500,
            },
          },
          discountAmount: {
            numericality: {
              greaterThan: 0,
            }
          },
          usesPerAccount: {
            numericality: {
              greaterThan: 0,
            }
          },
          totalUses: {
            numericality: {
              greaterThan: 0,
            }
          },
          dateRange: {
            presence: { allowEmpty: false },
          },
        },
      );

      if (validationErrors) {
        this.alertBox().fire({
          title: 'Validation Errors',
          icon: 'error',
          html: `<ul>${Object.values(validationErrors).join('</li><li>')}</ul>`,
        });
        return;
      }

      const voucher = {
        Id: this.voucher?.id,
        Code: this.code,
        Description: this.description,
        LimitTo: this.customersLimitedTo.length > 0 ? this.enums.discountLimitTo.LIMITED_TO_SPECIFIC_CUSTOMERS : this.enums.discountLimitTo.NONE,
        CustomerIdsLimitedTo: this.customersLimitedTo.map(x => x.id),
        UsesPerAccount: this.usesPerAccount,
        TotalUses: this.totalUses,
        DiscountType: this.discountType,
        DiscountAmount: this.discountAmount,
        StartDate: this.date_range ? new Date (this.date_range[0]) : undefined,
        EndDate: this.date_range ? new Date (this.date_range[1] + ' 23:59:59') : undefined,
        PerOrderOrItem: this.perOrderOrItem,
      }

      let response
      if (voucher.Id) {
        response = await window.touch.UpdateDiscountVoucher(voucher)
      } else {
        response = await window.touch.CreateDiscountVoucher(voucher)
      }

      if (response) {
        this.$emit('updated')
      }
    },
  },
};
</script>

<style scoped>

.container {
  min-height: 650px;
}


</style>
