<template>
  <transition name="fade-in" appear mode="out-in">
    <div class="h-screen bg-gray-200 sm:bg-transparent sm:overflow-hidden">
      <div class="text-brand-primary hidden sm:block">
        <div class="absolute top-0 right-0 -z-1 left-0">
          <img
            v-if="hasCustomSecondaryImage"
            :src="customSecondaryImage"
            class="ml-auto w-1/2"
          />
          <img v-else :src="$t('/images/login-background.png')" class="ml-auto w-1/2" />
        </div>
      </div>
      <header class="bg-white w-full sm:bg-transparent print:hidden">
        <div class="flex flex-grow">
          <logo
            class="border-0 bg-transparent"
            :src="style.fabricator_logo"
            :href="$t('https://store.bm-touch.co.uk')"
          ></logo>
          <div class="flex flex-grow flex-col"></div>
          <div class="flex">
            <div v-if="style.back_to_site_link" class="flex flex-col justify-center">
              <a
                :href="style.back_to_site_link"
                class="bg-white rounded-full hover:bg-gray-200 my-auto mx-3 sm:py-2 sm:pr-6 sm:pl-5 sm:m-5 sm:font-medium"
              >
                <i class="fal fa-browser mr-3"></i> <span>Back to site</span>
              </a>
            </div>
          </div>
        </div>
      </header>
      <div class="flex flex-col bg-gray-200 sm:bg-transparent">
        <div class="mx-auto w-full sm:flex justify-around" style="max-width: 1600px">
          <div class="sm:flex flex-col justify-around text-center p-10 sm:p-0 sm:m-10 sm:mr-0">
            <div class="flex flex-col gap-5 justify-between">
              <div class="text-3xl xs:text-5xl leading-tight font-medium">Create your Account</div>
              <div class="text-lg font-medium">In 4 simple steps</div>
              <div class="mx-auto">
                <div class="grid grid-cols-4 gap-5 border-solid border-t-2 border-blue-500 my-5 text-lg">
                  <div class="flex flex-col gap-3 items-center" :style="{ 'margin-top': '-20px' }">
                    <div
                      class="rounded-full border-solid text-center"
                      :class="[!loading && !isSubmit && !isVerify ? 'border-green-500 bg-green-500' : 'border-blue-500 bg-gray-500']"
                      :style="{
                        'min-width': '40px',
                        'min-height': '40px',
                        'max-width': '40px',
                        'max-height': '40px',
                      }"
                    >
                      <span class="text-white" :style="{ 'line-height': '40px' }">1</span>
                    </div>
                    <span style="max-width: 100px" class="text-xs sm:text-sm">Enter your details</span>
                  </div>
                  <div class="flex flex-col gap-3 items-center" :style="{ 'margin-top': '-20px' }">
                    <div
                      class="rounded-full border-solid text-center"
                      :class="[isSubmit ? 'border-green-500 bg-green-500' : 'border-blue-500 bg-gray-500']"
                      :style="{
                        'min-width': '40px',
                        'min-height': '40px',
                        'max-width': '40px',
                        'max-height': '40px',
                      }"
                    >
                      <span class="text-white" :style="{ 'line-height': '40px' }">2</span>
                    </div>
                    <span style="max-width: 100px" class="text-xs sm:text-sm">Verify your email address</span>
                  </div>
                  <div class="flex flex-col gap-3 items-center" :style="{ 'margin-top': '-20px' }">
                    <div
                      class="rounded-full border-solid text-center"
                      :class="[isVerify ? 'border-green-500 bg-green-500' : 'border-blue-500 bg-gray-500']"
                      :style="{
                        'min-width': '40px',
                        'min-height': '40px',
                        'max-width': '40px',
                        'max-height': '40px',
                      }"
                    >
                      <span class="text-white" :style="{ 'line-height': '40px' }">3</span>
                    </div>
                    <span style="max-width: 100px" class="text-xs sm:text-sm">Wait to be approved</span>
                  </div>
                  <div class="flex flex-col gap-3 items-center" :style="{ 'margin-top': '-20px' }">
                    <div
                      class="rounded-full border-solid border-blue-500 bg-gray-500 text-center"
                      :style="{
                        'min-width': '40px',
                        'min-height': '40px',
                        'max-width': '40px',
                        'max-height': '40px',
                      }"
                    >
                      <span class="text-white" :style="{ 'line-height': '40px' }">4</span>
                    </div>
                    <span style="max-width: 100px" class="text-xs sm:text-sm">Login to quote & order</span>
                  </div>
                </div>
              </div>
              <div class="mx-auto">
                <img
                  v-if="hasCustomImage"
                  :src="customImage"
                  class="m-auto w-full"
                />
                <img
                  v-else
                  :src="$t('/images/login-image.png')"
                  class="m-auto w-full"
                />
              </div>
            </div>
          </div>
          <div v-if="loading" class="login-form-wrapper my-auto">
            <div class="login-form">
              <loading :loading="loading"></loading>
            </div>
          </div>
          <div v-else-if="isVerify" class="login-form-wrapper my-auto">
            <div class="text-center bg-white py-0 hidden sm:block mb-4 login-form">
              <div class="text-4xl mb-2 font-medium">Thank you for validating your email address</div>
              <div class="text-lg mb-2">Your account request is being processed, we will be in touch shortly.</div>
            </div>
          </div>
          <div v-else-if="isSubmit" class="login-form-wrapper my-auto">
            <div class="text-center bg-white py-0 hidden sm:block mb-4 login-form">
              <div class="text-4xl mb-2 font-medium">Thank you for your request</div>
              <div class="text-lg mb-2">You will shortly receive an email to validate your email address.</div>
            </div>
          </div>
          <div v-else class="login-form-wrapper my-auto">
            <form class="login-form" @submit.prevent="register">
              <div class="text-center bg-white py-0 hidden sm:block mb-4">
                <div class="text-lg">{{ fabricator.name }}</div>
                <div class="text-4xl mb-2 font-medium">Register for an Account</div>
              </div>
              <div class="grid grid-cols-2 gap-4">
                <div class="col-span-2">
                  <label for="email" class="block w-full">Email <span class="text-red-600">*</span></label>
                  <input
                    v-model="email"
                    class="border border-solid border-gray-400 rounded block w-full p-2"
                    autocomplete="email"
                    type="email"
                  />
                </div>
                <div>
                  <label for="email" class="block w-full">Company <span class="text-red-600">*</span></label>
                  <input
                    v-model="companyName"
                    class="border border-solid border-gray-400 rounded block w-full p-2"
                  />
                </div>
                <div>
                  <label for="email" class="block w-full">Phone <span class="text-red-600">*</span></label>
                  <input
                    v-model="phone"
                    class="border border-solid border-gray-400 rounded block w-full p-2"
                  />
                </div>
                <div>
                  <label class="block w-full">Account Code (If Known)</label>
                  <input
                    v-model="accountCode"
                    class="border border-solid border-gray-400 rounded block w-full p-2"
                    autofocus
                  />
                </div>
                <div>
                  <label for="email" class="block w-full">Website</label>
                  <input
                    v-model="website"
                    class="border border-solid border-gray-400 rounded block w-full p-2"
                  />
                </div>
              </div>

              <button type="submit" class="btn-action btn-lg w-full mt-5">Register</button>
              <div class="text-center p-3">Got an Account? <router-link :to="'/login'"><strong>Sign In</strong></router-link></div>

              <div class="text-center mt-10 mb-10 sm:mb-0">
                <a
                  :href="'https://store.bm-touch.co.uk'"
                  target="_blank"
                  class="inline-flex bg-white border border-solid border-gray-400 rounded p-1"
                >
                  <div class="p-1">
                    <img src="../../assets/images/touch-logo.svg" width="75px" />
                  </div>
                  <div class="">
                    <template v-if="touch_portal_installation">
                      <img src="/images/touch-portal-icon.png" style="max-height: 27px" />
                    </template>
                  </div>
                  <div class="p-1 text-xs">By BM Group</div>
                </a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState } from 'vuex';
import Logo from '@/components/shared/nav/Logo.vue';
import validate from '@/mixins/validate';

export default {
  components: {
    Logo,
  },
  mixins: [
    validate
  ],
  data() {
    return {
      accountCode: '',
      email: '',
      companyName: '',
      website: '',
      phone: '',
      endpoint: process.env.VUE_APP_ENDPOINT_URL,
      imageBaseURL: process.env.VUE_APP_IMAGE_BASE_URL,
      loading: false,
      isSubmit: false,
      isVerify: false
    };
  },
  computed: {
    ...mapState('style', ['fabricator']),
    hasCustomImage() {
      return this.customImage && this.customImage.length;
    },
    hasCustomSecondaryImage() {
      return this.customSecondaryImage && this.customSecondaryImage.length;
    },
    customSecondaryImage() {
      return this.fabricator?.styling?.portalPageStyling?.loginSecondaryImageUri;
    },
    customImage() {
      return this.fabricator?.styling?.portalPageStyling?.loginImageUri;
    },
  },
  async mounted() {
    if (this.$route.query.code) {
      this.loading = true
      const response = await window.touch.VerifyEmail(this.$route.query.code)
      if (response?.status === 200) {
        this.isVerify = true
      } else {
        this.routerReplace('/register')
      }
      this.loading = false  
    }
  },
  methods: {
    async register() {

      const valid = this.validate([
        ['Email', this.email, 255, 'email'],
        ['Company Name', this.companyName, 64],
        ['Phone', this.phone, 64, 'number'],
        this.website ? ['Website', this.website, 255, 'website']: []
      ])

      this.loading = true;
      if (valid) {
        try {
          await window.touch.CreateTradeAccountApplication({
            accountCode: this.accountCode,
            email: this.email,
            companyName: this.companyName,
            website: this.website,
            phone: this.phone,
          })
          this.isSubmit = true
        } catch (error) {
          const message = error?.message || error?.data?.message
          this.alertBox().fire({
            title: message,
            icon: 'error',
          });
        }
      }
      this.loading = false;
    },
  },
};
</script>
