var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full"},[_c('div',{staticClass:"bg-white overflow-x-auto pl-3 md:pl-10"},[_c('div',{staticClass:"flex bg-white gap-10 border-t-4 border-white"},[_c('router-link',{staticClass:"flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black",class:{
          'border-black': _vm.$route.name === 'product-selections',
          'border-white': _vm.$route.name !== 'product-selections',
        },attrs:{"to":"/product-setup/product-selections"}},[_vm._v(" Product Selections ")]),(!_vm.isLeadGen)?_c('router-link',{staticClass:"flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black",class:{
          'border-black': _vm.$route.name === 'pricing',
          'border-white': _vm.$route.name !== 'pricing',
        },attrs:{"to":"/product-setup/pricing"}},[_vm._v(" Pricing ")]):_vm._e(),(!_vm.isLeadGen)?_c('router-link',{staticClass:"flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black",class:{
          'border-black': _vm.$route.name === 'parts',
          'border-white': _vm.$route.name !== 'parts',
        },attrs:{"to":"/product-setup/parts"}},[_vm._v(" Stock Parts ")]):_vm._e(),(!_vm.isLeadGen && _vm.$store.state.auth.superuser)?_c('router-link',{staticClass:"flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black",class:{
          'border-black': _vm.$route.name === 'touch-connect-business',
          'border-white': _vm.$route.name !== 'touch-connect-business',
        },attrs:{"to":"/product-setup/touch-connect-business"}},[_vm._v(" Touch Connect ")]):_vm._e(),(!_vm.isLeadGen)?_c('router-link',{staticClass:"flex-shrink-0 cursor-pointer border-b-4 py-2 gap-5 hover:border-black",class:{
          'border-black': _vm.$route.name === 'vouchers',
          'border-white': _vm.$route.name !== 'vouchers',
        },attrs:{"to":"/product-setup/vouchers"}},[_vm._v(" Vouchers ")]):_vm._e(),(!_vm.isLeadGen)?_c('router-link',{staticClass:"flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black",class:{
          'border-black': _vm.$route.name === 'discounts',
          'border-white': _vm.$route.name !== 'discounts',
        },attrs:{"to":"/product-setup/discounts"}},[_vm._v(" Discounts ")]):_vm._e(),(!_vm.isLeadGen && !_vm.$store.state.auth.accountSettings.hideFitting)?_c('router-link',{staticClass:"flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black",class:{'border-black' : _vm.$route.name === 'extras', 'border-white' : _vm.$route.name !== 'extras'},attrs:{"to":"/product-setup/extras"}},[_vm._v(" Extras ")]):_vm._e(),(!_vm.isLeadGen)?_c('router-link',{staticClass:"flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black text-sm",class:{
          'border-black': _vm.$route.name === 'option-lead-times',
          'border-white': _vm.$route.name !== 'option-lead-times',
        },attrs:{"to":"/product-setup/option-lead-times"}},[_vm._v(" Option Lead Times ")]):_vm._e(),_c('div',{staticClass:"pr-5"})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }